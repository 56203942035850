/*
just declare style for all
ex:
*/
@import url('https://fonts.googleapis.com/css?family=M+PLUS+1p:100,300,400,500,700,800,900&amp;subset=japanese');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

@import "variables";
@import "functions";
@import "uniti";
@import "~bootstrap";
@import "cdk/cdk";

html, body {
  height: 100%;
  min-width: 849px;
}

.no-opacity {
  opacity: 1;
}

.no-border {
  border: none;
}

.btn-blue:disabled {
  background-color: $gray-600;
  border-color: $gray-600;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
}

.input-disable {
  background-color: #e9ecef !important;
}

.scrollbar {
  //* restyle scrollbar */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: lighten($primary, 10);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: $primary-light;
  }
}

.master-css {
  background: $green-background;

  .sidebar-wrapper {
    background-color: $background-color-green !important;
  }

  .sidebar-wrapper::-webkit-scrollbar {
    width: 0.6em;
  }

  .sidebar-wrapper::-webkit-scrollbar-track {
    background: $green-background !important;
  }

  .sidebar-wrapper::-webkit-scrollbar-thumb {
    background-color: #4684a8 !important;
  }

  .sidenav {
    .hover-search:hover {
      background-color: #f8faf6;

      label {
        color: black;
      }
    }

    .form-control[readonly], .fa-calendar-alt {
      background-color: #f8faf6;
    }
  }

  table {
    border-left: 1px solid $background-color-green;
    border-right: 1px solid $background-color-green;
    border-bottom: 1px solid $background-color-green;

    > thead > tr > th {
      background-color: $background-color-green !important;
      color: $white !important;
    }
  }

  .body-content {
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100vh - 202px);
  }

  .body-content thead th {
    position: sticky;
    top: 0;
    clear: both;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tbody > .row-hover:hover {
    --bs-table-accent-bg: #BDD7EE;
    color: var(--bs-table-hover-color);
  }

  .row-color {
    background: #E7E6E6;
  }

  .top-item {
    background-color: $background-color-green !important;
    color: white;
    min-height: 70px;
    height: 20%;
    border: inset;
    font-size: 1.5rem;

    &:hover {
      border: solid 1px $background-color-green;
      background-color: #ffffff !important;
      color: black;
    }

    &:focus {
      border: solid 1px $background-color-green;
      outline: none;
    }
  }

  // css B2 top menu
  .box {
    //height: 48.5em;
    background-color: transparent;
    padding: 50px 0;
    @media screen and (max-width: 1280px),
    screen and (max-height: 625px) {
      height: auto;
      width: auto;
    }
    .box-left{
      .box-left-item{
        display: flex;
        justify-content: center;
        input{
          height: 95px;
          //width: 70%;
          background-color: #1F4E79 !important;
          color: white;
          border: none;
          font-size: 26px;
          outline: none;
          letter-spacing: 10px;
          box-shadow: inset 3px 3px 4px #e4e4e2, inset -5px -5px 7px #2f2f2f, 0px 1px 3px #e4e4e2, 0px 0px 2px #151515;
        }
        input:hover {
          background-color: white!important;
          color: #000;
        }

      }
    }
    .box-right{
      height: auto;
      background-color: #EBEEEE;
      border: 2px solid #6B83AE ;
      .box-right-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-top: 35px;

        input{
          background-color: #F7EFF9;
          height: 75px;
          width: 70%;
          border: none;
          margin-bottom: 30px;
          font-size: 25px;
          letter-spacing: 10px;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white;
        }
      }
      .box-right-provider{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-top: 35px;

        input{
          height: 75px;
          width: 70%;
          background-color: #D5F4F0;
          border: none;
          margin-bottom: 30px;
          font-size: 25px;
          letter-spacing: 10px;
          color: black;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white
        }
      }
    }
    .box-right-title{
      width: 48%;
      height: 54px;
      background-color: #009180;
      border-radius: 5px;
      border: 1px solid black;
      margin-top: 15px;
      color: white;
      display: flex;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      .box-right-title-text{
        width: 96%;
        padding-top: 1.8%;
        text-align: center;
        height: 75%;
        //box-shadow: 0 0 5px #333;
        box-shadow: inset 3px 3px 7px 1px #3d3c3c;
      }
    }
  }

  .box-sale {
    height: 32.8em;
    background-color: transparent;
    padding: 50px 0;
    @media screen and (max-width: 1280px),
    screen and (max-height: 625px) {
      height: auto;
      width: auto;
    }
    .box-left{
      .box-left-item{
        display: flex;
        justify-content: center;
        input{
          height: 95px;
          //width: 70%;
          background-color: #1F4E79 !important;
          color: white;
          border: none;
          font-size: 26px;
          outline: none;
          letter-spacing: 10px;
          box-shadow: inset 3px 3px 4px #e4e4e2, inset -5px -5px 7px #2f2f2f, 0px 1px 3px #e4e4e2, 0px 0px 2px #151515;
        }
        input:hover {
          background-color: white!important;
          color: #000;
        }

      }
    }
    .box-right{
      height: auto;
      background-color: #EBEEEE;
      border: 2px solid #6B83AE ;
      .box-right-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-top: 35px;

        input{
          background-color: $background-color-purple-light;
          height: 75px;
          width: 70%;
          border: none;
          margin-bottom: 30px;
          font-size: 25px;
          letter-spacing: 10px;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white;
        }
      }
      .box-right-provider{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-top: 35px;

        input{
          height: 75px;
          width: 70%;
          background-color: #D5F4F0;
          border: none;
          margin-bottom: 30px;
          font-size: 26px;
          letter-spacing: 10px;
          color: black;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white
        }
      }
    }
    .box-right-title{
      width: 48%;
      height: 54px;
      background-color: #009180;
      border-radius: 5px;
      border: 1px solid black;
      margin-top: 15px;
      color: white;
      display: flex;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      .box-right-title-text{
        width: 96%;
        padding-top: 1.8%;
        text-align: center;
        height: 75%;
        box-shadow: inset 3px 3px 7px 1px #3d3c3c;
      }
    }
  }

  .box-delivery {
    height: 33.8em;
    background-color: transparent;
    padding: 50px 0;
    @media screen and (max-width: 1280px),
    screen and (max-height: 625px) {
      height: auto;
      width: auto;
    }
    .box-left{
      .box-left-item{
        display: flex;
        justify-content: center;
        input{
          height: 95px;
          //width: 70%;
          background-color: #1F4E79 !important;
          color: white;
          border: none;
          font-size: 26px;
          outline: none;
          letter-spacing: 10px;
          box-shadow: inset 3px 3px 4px #e4e4e2, inset -5px -5px 7px #2f2f2f, 0px 1px 3px #e4e4e2, 0px 0px 2px #151515;
        }
        input:hover {
          background-color: white!important;
          color: #000;
        }

      }
    }
    .box-right{
      height: auto;
      background-color: #EBEEEE;
      border: 2px solid #6B83AE ;
      .box-right-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-top: 35px;

        input{
          background-color: $background-color-blue-plus;
          height: 75px;
          width: 70%;
          border: none;
          margin-bottom: 30px;
          font-size: 25px;
          letter-spacing: 10px;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white;
        }
      }
      .box-right-provider{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-top: 35px;

        input{
          height: 75px;
          width: 70%;
          background-color: #D5F4F0;
          border: none;
          margin-bottom: 30px;
          font-size: 26px;
          letter-spacing: 10px;
          color: black;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white
        }
      }
    }
    .box-right-title{
      width: 48%;
      height: 54px;
      background-color: #009180;
      border-radius: 5px;
      border: 1px solid black;
      margin-top: 15px;
      color: white;
      display: flex;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      .box-right-title-text{
        width: 96%;
        padding-top: 1.8%;
        text-align: center;
        height: 75%;
        box-shadow: inset 3px 3px 7px 1px #3d3c3c;
      }
    }
  }

  .box-customer {
    height: 25.8em;
    background-color: transparent;
    padding: 50px 0;
    @media screen and (max-width: 1280px),
    screen and (max-height: 625px) {
      height: auto;
      width: auto;
    }
    .box-left{
      .box-left-item{
        display: flex;
        justify-content: center;
        input{
          height: 95px;
          //width: 70%;
          background-color: #1F4E79 !important;
          color: white;
          border: none;
          font-size: 26px;
          outline: none;
          letter-spacing: 10px;
          box-shadow: inset 3px 3px 4px #e4e4e2, inset -5px -5px 7px #2f2f2f, 0px 1px 3px #e4e4e2, 0px 0px 2px #151515;
        }
        input:hover {
          background-color: white!important;
          color: #000;
        }

      }
    }
    .box-right{
      height: auto;
      background-color: #EBEEEE;
      border: 2px solid #6B83AE ;
      .box-right-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        margin-top: 35px;

        input{
          background-color: $background-color-orange-light;
          height: 75px;
          width: 70%;
          border: none;
          margin-bottom: 30px;
          font-size: 25px;
          letter-spacing: 10px;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white;
        }
      }
      .box-right-provider{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-top: 35px;

        input{
          height: 75px;
          width: 70%;
          background-color: #D5F4F0;
          border: none;
          margin-bottom: 30px;
          font-size: 26px;
          letter-spacing: 10px;
          color: black;
          box-shadow: inset 5px 5px 4px #ffffff, inset -5px -5px 4px #8e8e8e;
        }
        input:hover {
          background-color: white
        }
      }
    }
    .box-right-title{
      width: 48%;
      height: 54px;
      background-color: #009180;
      border-radius: 5px;
      border: 1px solid black;
      margin-top: 15px;
      color: black;
      display: flex;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      .box-right-title-text{
        width: 96%;
        padding-top: 1.8%;
        text-align: center;
        height: 75%;
        box-shadow: inset 3px 3px 7px 1px #3d3c3c;
      }
    }
  }

  .text-overflow{
    display: -webkit-box;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

    .btn-outline-mpro-primary {
      position: relative;
      max-width: 96px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }
    .btn-outline-mpro-primary-2 {
      position: relative;
      max-width: 96px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export {
      position: relative;
      max-width: 96px;
      height: 58px;

      background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }
    .btn-header {
      width: 106px;
      height: 57px;
    }
    .btn-header-2 {
      width: 106px;
      height: 57px;
    }
    .btn-header-3 {
      width: 95px;
    }
    .btn-header-right {
      margin-top: 0rem;
      float: none;
    }
    .btn-header-right2 {
      //margin-top: 0.5rem;
      float: right;
    }
    .btn-header-left {
      float: left;
    }

    .hard-width-com-provinder{
      width: 55px;
    }
    .hard-width-1-com-provinder{
      width: 125px;
    }
    .hard-width-2-com-provinder{
      width: 305px;
    }
    .hard-width-3-com-provinder{
      width: 122px;
    }
    .hard-width-4-com-provinder{
      width: 122px;
    }
    .hard-width-5-com-provinder{
      width: 160px;
    }
    .hard-width-6-com-provinder{
      width: 135px;
    }
    .hard-width-7-com-provinder{
      width: 113px;
    }
    .hard-width-8-com-provinder{
      width: 78px;
    }
    .hard-width-9-com-provinder{
      width: 78px;
    }
    .hard-width-10-com-provinder{
      width: 135px;
    }
    .hard-width-11-com-provinder{
      width: 78px;
    }

    .hard-width-com-delivery{
      width: 55px;
    }
    .hard-width-1-com-delivery{
      width: 125px;
    }
    .hard-width-2-com-delivery{
      width: 305px;
    }
    .hard-width-3-com-delivery{
      width: 122px;
    }
    .hard-width-4-com-delivery{
      width: 122px;
    }
    .hard-width-5-com-delivery{
      width: 160px;
    }
    .hard-width-6-com-delivery{
      width: 135px;
    }
    .hard-width-7-com-delivery{
      width: 113px;
    }
    .hard-width-8-com-delivery{
      width: 78px;
    }
    .hard-width-9-com-delivery{
      width: 78px;
    }
    .hard-width-10-com-delivery{
      width: 135px;
    }
    .hard-width-11-com-delivery{
      width: 78px;
    }
    .hard-width-mm-provinder{
      width: 59px;
    }
    .hard-width-1-mm-provinder{
      width: 129px;
    }
    .hard-width-2-mm-provinder{
      width: 315px;
    }
    .hard-width-3-mm-provinder{
      width: 126px;
    }
    .hard-width-4-mm-provinder{
      width: 126px;
    }
    .hard-width-5-mm-provinder{
      width: 224px;
    }
    .hard-width-6-mm-provinder{
      width: 139px;
    }
    .hard-width-7-mm-provinder{
      width: 116px;
    }
    .hard-width-8-mm-provinder{
      width: 80px;
    }
    .hard-width-9-mm-provinder{
      width: 80px;
    }
    .hard-width-10-mm-provinder{
      width: 80px;
    }
    .hard-width-11-mm-provinder{
      width: 80px;
    }

    .hard-width-mm-delivery{
      width: 59px;
    }
    .hard-width-1-mm-delivery{
      width: 129px;
    }
    .hard-width-2-mm-delivery{
      width: 315px;
    }
    .hard-width-3-mm-delivery{
      width: 126px;
    }
    .hard-width-4-mm-delivery{
      width: 126px;
    }
    .hard-width-5-mm-delivery{
      width: 224px;
    }
    .hard-width-6-mm-delivery{
      width: 139px;
    }
    .hard-width-7-mm-delivery{
      width: 116px;
    }
    .hard-width-8-mm-delivery{
      width: 80px;
    }
    .hard-width-9-mm-delivery{
      width: 80px;
    }
    .hard-width-10-mm-delivery{
      width: 80px;
    }
    .hard-width-11-mm-delivery{
      width: 80px;
    }


    .hard-width-mm-sale{
      width: 59px;
    }
    .hard-width-1-mm-sale{
      width: 129px;
    }
    .hard-width-2-mm-sale{
      width: 315px;
    }
    .hard-width-3-mm-sale{
      width: 126px;
    }
    .hard-width-4-mm-sale{
      width: 126px;
    }
    .hard-width-5-mm-sale{
      width: 224px;
    }
    .hard-width-6-mm-sale{
      width: 139px;
    }
    .hard-width-7-mm-sale{
      width: 116px;
    }
    .hard-width-8-mm-sale{
      width: 80px;
    }
    .hard-width-9-mm-sale{
      width: 80px;
    }
    .hard-width-10-mm-sale-confrim{
      width: 160px;
    }
    .hard-width-10-mm-sale{
      width: 80px;
    }
    .hard-width-11-mm-sale{
      width: 80px;
    }

    .hard-width-mm-customer{
      width: 59px;
    }
    .hard-width-1-mm-customer{
      width: 129px;
    }
    .hard-width-2-mm-customer{
      width: 315px;
    }
    .hard-width-3-mm-customer{
      width: 126px;
    }
    .hard-width-4-mm-customer{
      width: 126px;
    }
    .hard-width-5-mm-customer{
      width: 224px;
    }
    .hard-width-6-mm-customer{
      width: 139px;
    }
    .hard-width-7-mm-customer{
      width: 116px;
    }
    .hard-width-8-mm-customer{
      width: 80px;
    }
    .hard-width-9-mm-customer{
      width: 80px;
    }
    .hard-width-10-mm-customer{
      width: 80px;
    }
    .hard-width-11-mm-customer{
      width: 80px;
    }
    .hard-width-des-provinder{
      width: 53px;
    }
    .hard-width-1-des-provinder{
      width: 117px;
    }
    .hard-width-2-des-provinder{
      width: 179px;
    }
    .hard-width-3-des-provinder{
      width: 114px;
    }
    .hard-width-4-des-provinder{
      width: 114px;
    }
    .hard-width-5-des-provinder{
      width: 174px;
    }
    .hard-width-6-des-provinder{
      width: 126px;
    }
    .hard-width-7-des-provinder{
      width: 105px;
    }
    .hard-width-8-des-provinder{
      width: 97px;
    }
    .hard-width-9-des-provinder{
      width: 93px;
    }
    .hard-width-10-des-provinder{
      width: 165px;
    }
    .hard-width-11-des-provinder{
      width: 71px;
    }
    .hard-width-des-delivery{
      width: 55px;
    }
    .hard-width-1-des-delivery{
      width: 117px;
    }
    .hard-width-2-des-delivery{
      width: 178px;
    }
    .hard-width-3-des-delivery{
      width: 113px;
    }
    .hard-width-4-des-delivery{
      width: 113px;
    }
    .hard-width-6-des-delivery{
      width: 173px;

    }
    .hard-width-7-des-delivery{
      width: 125px;

    }
    .hard-width-8-des-delivery{
      width: 105px;
    }
    .hard-width-9-des-delivery{
      width: 422px;
    }
    .hard-width-1{
      width: 136px;
    }
    .hard-width-2{
      width: 180px;
    }
    .hard-width-2-1{
      width: 180px;
    }
    .hard-width-5{
      width: 180px;
    }
    .hard-width-3{
      width: 118px;
    }
    .hard-width-4{
      width: 120px;
    }
    .hard-width-6{
      width: 140px;
    }
    .hard-width-6-1{
      width: 180px;
    }
    .hard-width-7{
      width: 130px;
    }
    .hard-width-8{
      width: 112px;
    }
    .hard-width-9{
      width: 110px;
    }
    .hard-width-10{
      width: 159px;
    }
    .hard-width-10-1{
      width: 85px;
    }
    .hard-width-11{
      width: 70px;
    }
    .hard-width-11-1{
      width: 85px;
    }

    // css th customer
    .cus-hard-width-1{
      width: 180px !important;
    }
    .cus-hard-width-2{
      width: 200px !important;
    }
    .cus-hard-width-3{
      width: 160px !important;
    }
    .cus-hard-width-4{
      width: 140px !important;
    }
    .cus-hard-width-5{
      width: 170px !important;
    }
    .cus-hard-width-6{
      width: 170px !important;
    }
    .cus-hard-width-7{
      width: 140px !important;
    }
    .cus-hard-width-8{
      width: 140px !important;
    }

    // css th sale
    .sale-hard-width-1{
      width: 200px !important;
    }
    .sale-hard-width-2{
      width: 180px !important;
    }
    .sale-hard-width-3{
      width: 170px !important;
    }
    .sale-hard-width-4{
      width: 170px !important;
    }
    .sale-hard-width-5{
      width: 105px !important;
    }

    // css th delivery
    .deli-hard-width-1{
      width: 265px !important;
    }
    .deli-hard-width-2{
      width: 180px !important;
    }
    .deli-hard-width-3{
      width: 170px !important;
    }
    .deli-hard-width-4{
      width: 170px !important;
    }
    .deli-hard-width-5{
      width: 105px !important;
    }
  }

  // Large devices (desktops, 992px and up)
  @media screen and (min-width: 992px) and (max-width:1366px) {
    .btn-outline-mpro-primary {
      position: relative;
      max-width: 96px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }
    .btn-outline-mpro-primary-2 {
      position: relative;
      max-width: 96px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export {
      position: relative;
      max-width: 96px;
      height: 58px;

      background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }
    .btn-header {
      width: 106px;
      height: 57px;
    }
    .btn-header-2 {
      width: 106px;
      height: 57px;
    }
    .btn-header-3 {
      width: 95px;
    }
    .btn-header-right {
      margin-top: 0rem;
      float: none;
    }
    .btn-header-right2 {
      //margin-top: 0.5rem;
      float: right;
    }
    .btn-header-left {
      float: left;
    }
    .btn-header-left-history-delivery{
      float: none;
    }


    .hard-width-com-provinder{
      width: 55px;
    }
    .hard-width-1-com-provinder{
      width: 125px;
    }
    .hard-width-2-com-provinder{
      width: 305px;
    }
    .hard-width-3-com-provinder{
      width: 122px;
    }
    .hard-width-4-com-provinder{
      width: 122px;
    }
    .hard-width-5-com-provinder{
      width: 160px;
    }
    .hard-width-6-com-provinder{
      width: 135px;
    }
    .hard-width-7-com-provinder{
      width: 113px;
    }
    .hard-width-8-com-provinder{
      width: 78px;
    }
    .hard-width-9-com-provinder{
      width: 78px;
    }
    .hard-width-10-com-provinder{
      width: 135px;
    }
    .hard-width-11-com-provinder{
      width: 78px;
    }

    .hard-width-com-delivery{
      width: 55px;
    }
    .hard-width-1-com-delivery{
      width: 125px;
    }
    .hard-width-2-com-delivery{
      width: 305px;
    }
    .hard-width-3-com-delivery{
      width: 122px;
    }
    .hard-width-4-com-delivery{
      width: 122px;
    }
    .hard-width-5-com-delivery{
      width: 160px;
    }
    .hard-width-6-com-delivery{
      width: 135px;
    }
    .hard-width-7-com-delivery{
      width: 113px;
    }
    .hard-width-8-com-delivery{
      width: 78px;
    }
    .hard-width-9-com-delivery{
      width: 78px;
    }
    .hard-width-10-com-delivery{
      width: 135px;
    }
    .hard-width-11-com-delivery{
      width: 78px;
    }
    .hard-width-mm-provinder{
      width: 59px;
    }
    .hard-width-1-mm-provinder{
      width: 129px;
    }
    .hard-width-2-mm-provinder{
      width: 315px;
    }
    .hard-width-3-mm-provinder{
      width: 126px;
    }
    .hard-width-4-mm-provinder{
      width: 126px;
    }
    .hard-width-5-mm-provinder{
      width: 224px;
    }
    .hard-width-6-mm-provinder{
      width: 139px;
    }
    .hard-width-7-mm-provinder{
      width: 116px;
    }
    .hard-width-8-mm-provinder{
      width: 80px;
    }
    .hard-width-9-mm-provinder{
      width: 80px;
    }
    .hard-width-10-mm-provinder{
      width: 80px;
    }
    .hard-width-11-mm-provinder{
      width: 80px;
    }

    .hard-width-mm-delivery{
      width: 59px;
    }
    .hard-width-1-mm-delivery{
      width: 129px;
    }
    .hard-width-2-mm-delivery{
      width: 315px;
    }
    .hard-width-3-mm-delivery{
      width: 126px;
    }
    .hard-width-4-mm-delivery{
      width: 126px;
    }
    .hard-width-5-mm-delivery{
      width: 224px;
    }
    .hard-width-6-mm-delivery{
      width: 139px;
    }
    .hard-width-7-mm-delivery{
      width: 116px;
    }
    .hard-width-8-mm-delivery{
      width: 80px;
    }
    .hard-width-9-mm-delivery{
      width: 80px;
    }
    .hard-width-10-mm-delivery{
      width: 80px;
    }
    .hard-width-11-mm-delivery{
      width: 80px;
    }


    .hard-width-mm-sale{
      width: 59px;
    }
    .hard-width-1-mm-sale{
      width: 129px;
    }
    .hard-width-2-mm-sale{
      width: 315px;
    }
    .hard-width-3-mm-sale{
      width: 126px;
    }
    .hard-width-4-mm-sale{
      width: 126px;
    }
    .hard-width-5-mm-sale{
      width: 224px;
    }
    .hard-width-6-mm-sale{
      width: 139px;
    }
    .hard-width-7-mm-sale{
      width: 116px;
    }
    .hard-width-8-mm-sale{
      width: 80px;
    }
    .hard-width-9-mm-sale{
      width: 80px;
    }
    .hard-width-10-mm-sale-confrim{
      width: 160px;
    }
    .hard-width-10-mm-sale{
      width: 80px;
    }
    .hard-width-11-mm-sale{
      width: 80px;
    }

    .hard-width-mm-customer{
      width: 59px;
    }
    .hard-width-1-mm-customer{
      width: 129px;
    }
    .hard-width-2-mm-customer{
      width: 315px;
    }
    .hard-width-3-mm-customer{
      width: 126px;
    }
    .hard-width-4-mm-customer{
      width: 126px;
    }
    .hard-width-5-mm-customer{
      width: 224px;
    }
    .hard-width-6-mm-customer{
      width: 139px;
    }
    .hard-width-7-mm-customer{
      width: 116px;
    }
    .hard-width-8-mm-customer{
      width: 80px;
    }
    .hard-width-9-mm-customer{
      width: 80px;
    }
    .hard-width-10-mm-customer{
      width: 80px;
    }
    .hard-width-11-mm-customer{
      width: 80px;
    }
    .hard-width-des-provinder{
      width: 53px;
    }
    .hard-width-1-des-provinder{
      width: 117px;
    }
    .hard-width-2-des-provinder{
      width: 179px;
    }
    .hard-width-3-des-provinder{
      width: 114px;
    }
    .hard-width-4-des-provinder{
      width: 114px;
    }
    .hard-width-5-des-provinder{
      width: 174px;
    }
    .hard-width-6-des-provinder{
      width: 126px;
    }
    .hard-width-7-des-provinder{
      width: 105px;
    }
    .hard-width-8-des-provinder{
      width: 97px;
    }
    .hard-width-9-des-provinder{
      width: 93px;
    }
    .hard-width-10-des-provinder{
      width: 165px;
    }
    .hard-width-11-des-provinder{
      width: 71px;
    }
    .hard-width-des-delivery{
      width: 55px;
    }
    .hard-width-1-des-delivery{
      width: 117px;
    }
    .hard-width-2-des-delivery{
      width: 178px;
    }
    .hard-width-3-des-delivery{
      width: 113px;
    }
    .hard-width-4-des-delivery{
      width: 113px;
    }
    .hard-width-6-des-delivery{
      width: 173px;

    }
    .hard-width-7-des-delivery{
      width: 125px;

    }
    .hard-width-8-des-delivery{
      width: 105px;
    }
    .hard-width-9-des-delivery{
      width: 422px;
    }
    .hard-width-1{
      width: 136px;
    }
    .hard-width-2{
      width: 180px;
    }
    .hard-width-2-1{
      width: 180px;
    }
    .hard-width-5{
      width: 180px;
    }
    .hard-width-3{
      width: 118px;
    }
    .hard-width-4{
      width: 120px;
    }
    .hard-width-6{
      width: 140px;
    }
    .hard-width-6-1{
      width: 180px;
    }
    .hard-width-7{
      width: 130px;
    }
    .hard-width-8{
      width: 112px;
    }
    .hard-width-9{
      width: 110px;
    }
    .hard-width-10{
      width: 159px;
    }
    .hard-width-10-1{
      width: 85px;
    }
    .hard-width-11{
      width: 70px;
    }
    .hard-width-11-1{
      width: 85px;
    }

    // css th customer
    .cus-hard-width-1{
      width: 180px !important;
    }
    .cus-hard-width-2{
      width: 200px !important;
    }
    .cus-hard-width-3{
      width: 160px !important;
    }
    .cus-hard-width-4{
      width: 140px !important;
    }
    .cus-hard-width-5{
      width: 170px !important;
    }
    .cus-hard-width-6{
      width: 170px !important;
    }
    .cus-hard-width-7{
      width: 140px !important;
    }
    .cus-hard-width-8{
      width: 140px !important;
    }

    // css th sale
    .sale-hard-width-1{
      width: 200px !important;
    }
    .sale-hard-width-2{
      width: 180px !important;
    }
    .sale-hard-width-3{
      width: 170px !important;
    }
    .sale-hard-width-4{
      width: 170px !important;
    }
    .sale-hard-width-5{
      width: 105px !important;
    }

    // css th delivery
    .deli-hard-width-1{
      width: 265px !important;
    }
    .deli-hard-width-2{
      width: 180px !important;
    }
    .deli-hard-width-3{
      width: 170px !important;
    }
    .deli-hard-width-4{
      width: 170px !important;
    }
    .deli-hard-width-5{
      width: 105px !important;
    }
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media  screen and (min-width: 1440px) and (max-width:1919px){
    .btn-outline-mpro-primary {
      position: relative;
      max-width: 96px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }
    .btn-outline-mpro-primary-2 {
      position: relative;
      max-width: 96px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export {
      position: relative;
      max-width: 96px;
      height: 58px;

      background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }
    .btn-header {
      width: 106px;
      height: 57px;
    }
    .btn-header-2 {
      width: 106px;
      height: 57px;
    }
    .btn-header-3 {
      width: 95px;
    }
    .btn-header-right {
      margin-top: 0rem;
      float: right;
    }
    .btn-header-right2 {
      //margin-top: 0.5rem;
      float: right;
    }
    .btn-header-left {
      float: left;
    }
    .btn-header-left-history-delivery{
      float: left;
    }

    .hard-width-com-provinder{
      width: 55px;
    }
    .hard-width-1-com-provinder{
      width: 125px;
    }
    .hard-width-2-com-provinder{
      width: 305px;
    }
    .hard-width-3-com-provinder{
      width: 122px;
    }
    .hard-width-4-com-provinder{
      width: 122px;
    }
    .hard-width-5-com-provinder{
      width: 160px;
    }
    .hard-width-6-com-provinder{
      width: 135px;
    }
    .hard-width-7-com-provinder{
      width: 113px;
    }
    .hard-width-8-com-provinder{
      width: 78px;
    }
    .hard-width-9-com-provinder{
      width: 78px;
    }
    .hard-width-10-com-provinder{
      width: 135px;
    }
    .hard-width-11-com-provinder{
      width: 78px;
    }

    .hard-width-com-delivery{
      width: 55px;
    }
    .hard-width-1-com-delivery{
      width: 125px;
    }
    .hard-width-2-com-delivery{
      width: 305px;
    }
    .hard-width-3-com-delivery{
      width: 122px;
    }
    .hard-width-4-com-delivery{
      width: 122px;
    }
    .hard-width-5-com-delivery{
      width: 160px;
    }
    .hard-width-6-com-delivery{
      width: 135px;
    }
    .hard-width-7-com-delivery{
      width: 113px;
    }
    .hard-width-8-com-delivery{
      width: 78px;
    }
    .hard-width-9-com-delivery{
      width: 78px;
    }
    .hard-width-10-com-delivery{
      width: 135px;
    }
    .hard-width-11-com-delivery{
      width: 78px;
    }
    .hard-width-mm-provinder{
      width: 59px;
    }
    .hard-width-1-mm-provinder{
      width: 129px;
    }
    .hard-width-2-mm-provinder{
      width: 315px;
    }
    .hard-width-3-mm-provinder{
      width: 126px;
    }
    .hard-width-4-mm-provinder{
      width: 126px;
    }
    .hard-width-5-mm-provinder{
      width: 224px;
    }
    .hard-width-6-mm-provinder{
      width: 139px;
    }
    .hard-width-7-mm-provinder{
      width: 116px;
    }
    .hard-width-8-mm-provinder{
      width: 80px;
    }
    .hard-width-9-mm-provinder{
      width: 80px;
    }
    .hard-width-10-mm-provinder{
      width: 80px;
    }
    .hard-width-11-mm-provinder{
      width: 80px;
    }

    .hard-width-mm-delivery{
      width: 59px;
    }
    .hard-width-1-mm-delivery{
      width: 129px;
    }
    .hard-width-2-mm-delivery{
      width: 315px;
    }
    .hard-width-3-mm-delivery{
      width: 126px;
    }
    .hard-width-4-mm-delivery{
      width: 126px;
    }
    .hard-width-5-mm-delivery{
      width: 224px;
    }
    .hard-width-6-mm-delivery{
      width: 139px;
    }
    .hard-width-7-mm-delivery{
      width: 116px;
    }
    .hard-width-8-mm-delivery{
      width: 80px;
    }
    .hard-width-9-mm-delivery{
      width: 80px;
    }
    .hard-width-10-mm-delivery{
      width: 80px;
    }
    .hard-width-11-mm-delivery{
      width: 80px;
    }


    .hard-width-mm-sale{
      width: 59px;
    }
    .hard-width-1-mm-sale{
      width: 129px;
    }
    .hard-width-2-mm-sale{
      width: 315px;
    }
    .hard-width-3-mm-sale{
      width: 126px;
    }
    .hard-width-4-mm-sale{
      width: 126px;
    }
    .hard-width-5-mm-sale{
      width: 224px;
    }
    .hard-width-6-mm-sale{
      width: 139px;
    }
    .hard-width-7-mm-sale{
      width: 116px;
    }
    .hard-width-8-mm-sale{
      width: 80px;
    }
    .hard-width-9-mm-sale{
      width: 80px;
    }
    .hard-width-10-mm-sale-confrim{
      width: 160px;
    }
    .hard-width-10-mm-sale{
      width: 80px;
    }
    .hard-width-11-mm-sale{
      width: 80px;
    }

    .hard-width-mm-customer{
      width: 59px;
    }
    .hard-width-1-mm-customer{
      width: 129px;
    }
    .hard-width-2-mm-customer{
      width: 315px;
    }
    .hard-width-3-mm-customer{
      width: 126px;
    }
    .hard-width-4-mm-customer{
      width: 126px;
    }
    .hard-width-5-mm-customer{
      width: 224px;
    }
    .hard-width-6-mm-customer{
      width: 139px;
    }
    .hard-width-7-mm-customer{
      width: 116px;
    }
    .hard-width-8-mm-customer{
      width: 80px;
    }
    .hard-width-9-mm-customer{
      width: 80px;
    }
    .hard-width-10-mm-customer{
      width: 80px;
    }
    .hard-width-11-mm-customer{
      width: 80px;
    }.hard-width-des-provinder{
       width: 53px;
     }
    .hard-width-1-des-provinder{
      width: 117px;
    }
    .hard-width-2-des-provinder{
      width: 179px;
    }
    .hard-width-3-des-provinder{
      width: 114px;
    }
    .hard-width-4-des-provinder{
      width: 114px;
    }
    .hard-width-5-des-provinder{
      width: 174px;
    }
    .hard-width-6-des-provinder{
      width: 126px;
    }
    .hard-width-7-des-provinder{
      width: 105px;
    }
    .hard-width-8-des-provinder{
      width: 97px;
    }
    .hard-width-9-des-provinder{
      width: 93px;
    }
    .hard-width-10-des-provinder{
      width: 165px;
    }
    .hard-width-11-des-provinder{
      width: 71px;
    }
    .hard-width-des-delivery{
      width: 55px;
    }
    .hard-width-1-des-delivery{
      width: 117px;
    }
    .hard-width-2-des-delivery{
      width: 178px;
    }
    .hard-width-3-des-delivery{
      width: 113px;
    }
    .hard-width-4-des-delivery{
      width: 113px;
    }
    .hard-width-6-des-delivery{
      width: 173px;

    }
    .hard-width-7-des-delivery{
      width: 125px;

    }
    .hard-width-8-des-delivery{
      width: 105px;
    }
    .hard-width-9-des-delivery{
      width: 422px;
    }
    .hard-width-1{
      width: 136px;
    }
    .hard-width-2{
      width: 180px;
    }
    .hard-width-2-1{
      width: 180px;
    }
    .hard-width-5{
      width: 180px;
    }
    .hard-width-3{
      width: 118px;
    }
    .hard-width-4{
      width: 120px;
    }
    .hard-width-6{
      width: 140px;
    }
    .hard-width-6-1{
      width: 180px;
    }
    .hard-width-7{
      width: 130px;
    }
    .hard-width-8{
      width: 112px;
    }
    .hard-width-9{
      width: 110px;
    }
    .hard-width-10{
      width: 159px;
    }
    .hard-width-10-1{
      width: 85px;
    }
    .hard-width-11{
      width: 70px;
    }
    .hard-width-11-1{
      width: 85px;
    }

    // css th customer
    .cus-hard-width-1{
      width: 180px !important;
    }
    .cus-hard-width-2{
      width: 200px !important;
    }
    .cus-hard-width-3{
      width: 160px !important;
    }
    .cus-hard-width-4{
      width: 140px !important;
    }
    .cus-hard-width-5{
      width: 170px !important;
    }
    .cus-hard-width-6{
      width: 170px !important;
    }
    .cus-hard-width-7{
      width: 140px !important;
    }
    .cus-hard-width-8{
      width: 140px !important;
    }

    // css th sale
    .sale-hard-width-1{
      width: 200px !important;
    }
    .sale-hard-width-2{
      width: 180px !important;
    }
    .sale-hard-width-3{
      width: 170px !important;
    }
    .sale-hard-width-4{
      width: 170px !important;
    }
    .sale-hard-width-5{
      width: 170px !important;
    }

    // css th delivery
    .deli-hard-width-1{
      width: 265px !important;
    }
    .deli-hard-width-2{
      width: 180px !important;
    }
    .deli-hard-width-3{
      width: 170px !important;
    }
    .deli-hard-width-4{
      width: 170px !important;
    }
    .deli-hard-width-5{
      width: 170px !important;
    }
  }
  @media  (min-width: 1440px) {
    .btn_delivery_history {
      margin-left: 100px !important;
    }
  }
  @media  (min-width: 1920px) {
    .btn-outline-mpro-primary {
      position: relative;
      max-width: 96px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }
    .btn-outline-mpro-primary-2 {
      position: relative;
      max-width: 113px;
      height: 58px;

      //background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export {
      position: relative;
      max-width: 113px;
      height: 58px;

      background: #4C6E90;
      box-shadow: inset 0px -2px 3px 3px #4A5B6E;
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::before {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-outline-mpro-primary-export::after {
      content: "";
      display: block;
      position: absolute;
      width: 90%;
      height: 3px;
      top: 0.1rem;

      background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
      filter: blur(3px);
      border-radius: 8px;
    }

    .btn-header {
      width: 90px;
      height: 57px;
    }
    .btn-header-2 {
      width: 106px;
      height: 57px;
    }
    .btn-header-3 {
      width: 90px;
      //margin: 5px !important;
    }
    .btn-header-right {
      margin-top: 0rem;
      float: right;
    }
    .btn-header-right2 {
      //margin-top: 0.5rem;
      float: right;
    }
    .btn-header-left {
      float: left;
    }
    .btn-header-left-history-delivery{
      float: left;
    }

    .hard-width-com-provinder{
      width: 55px;
    }
    .hard-width-1-com-provinder{
      width: 125px;
    }
    .hard-width-2-com-provinder{
      width: 305px;
    }
    .hard-width-3-com-provinder{
      width: 122px;
    }
    .hard-width-4-com-provinder{
      width: 122px;
    }
    .hard-width-5-com-provinder{
      width: 160px;
    }
    .hard-width-6-com-provinder{
      width: 135px;
    }
    .hard-width-7-com-provinder{
      width: 113px;
    }
    .hard-width-8-com-provinder{
      width: 78px;
    }
    .hard-width-9-com-provinder{
      width: 78px;
    }
    .hard-width-10-com-provinder{
      width: 135px;
    }
    .hard-width-11-com-provinder{
      width: 78px;
    }

    .hard-width-com-delivery{
      width: 55px;
    }
    .hard-width-1-com-delivery{
      width: 125px;
    }
    .hard-width-2-com-delivery{
      width: 305px;
    }
    .hard-width-3-com-delivery{
      width: 122px;
    }
    .hard-width-4-com-delivery{
      width: 122px;
    }
    .hard-width-5-com-delivery{
      width: 160px;
    }
    .hard-width-6-com-delivery{
      width: 135px;
    }
    .hard-width-7-com-delivery{
      width: 113px;
    }
    .hard-width-8-com-delivery{
      width: 78px;
    }
    .hard-width-9-com-delivery{
      width: 78px;
    }
    .hard-width-10-com-delivery{
      width: 135px;
    }
    .hard-width-11-com-delivery{
      width: 78px;
    }

    .hard-width-mm-provinder{
      width: 59px;
    }
    .hard-width-1-mm-provinder{
      width: 129px;
    }
    .hard-width-2-mm-provinder{
      width: 315px;
    }
    .hard-width-3-mm-provinder{
      width: 126px;
    }
    .hard-width-4-mm-provinder{
      width: 126px;
    }
    .hard-width-5-mm-provinder{
      width: 224px;
    }
    .hard-width-6-mm-provinder{
      width: 139px;
    }
    .hard-width-7-mm-provinder{
      width: 116px;
    }
    .hard-width-8-mm-provinder{
      width: 80px;
    }
    .hard-width-9-mm-provinder{
      width: 80px;
    }
    .hard-width-10-mm-provinder{
      width: 80px;
    }
    .hard-width-11-mm-provinder{
      width: 80px;
    }

    .hard-width-mm-delivery{
      width: 59px;
    }
    .hard-width-1-mm-delivery{
      width: 129px;
    }
    .hard-width-2-mm-delivery{
      width: 315px;
    }
    .hard-width-3-mm-delivery{
      width: 126px;
    }
    .hard-width-4-mm-delivery{
      width: 126px;
    }
    .hard-width-5-mm-delivery{
      width: 224px;
    }
    .hard-width-6-mm-delivery{
      width: 139px;
    }
    .hard-width-7-mm-delivery{
      width: 116px;
    }
    .hard-width-8-mm-delivery{
      width: 80px;
    }
    .hard-width-9-mm-delivery{
      width: 80px;
    }
    .hard-width-10-mm-delivery{
      width: 80px;
    }
    .hard-width-11-mm-delivery{
      width: 80px;
    }

    .hard-width-mm-sale{
      width: 59px;
    }
    .hard-width-1-mm-sale{
      width: 129px;
    }
    .hard-width-2-mm-sale{
      width: 315px;
    }
    .hard-width-3-mm-sale{
      width: 126px;
    }
    .hard-width-4-mm-sale{
      width: 126px;
    }
    .hard-width-5-mm-sale{
      width: 224px;
    }
    .hard-width-6-mm-sale{
      width: 139px;
    }
    .hard-width-7-mm-sale{
      width: 116px;
    }
    .hard-width-8-mm-sale{
      width: 80px;
    }
    .hard-width-9-mm-sale{
      width: 80px;
    }
    .hard-width-10-mm-sale-confrim{
      width: 160px;
    }
    .hard-width-10-mm-sale{
      width: 80px;
    }
    .hard-width-11-mm-sale{
      width: 80px;
    }

    .hard-width-mm-customer{
      width: 59px;
    }
    .hard-width-1-mm-customer{
      width: 129px;
    }
    .hard-width-2-mm-customer{
      width: 315px;
    }
    .hard-width-3-mm-customer{
      width: 126px;
    }
    .hard-width-4-mm-customer{
      width: 126px;
    }
    .hard-width-5-mm-customer{
      width: 224px;
    }
    .hard-width-6-mm-customer{
      width: 139px;
    }
    .hard-width-7-mm-customer{
      width: 116px;
    }
    .hard-width-8-mm-customer{
      width: 80px;
    }
    .hard-width-9-mm-customer{
      width: 80px;
    }
    .hard-width-10-mm-customer{
      width: 80px;
    }
    .hard-width-11-mm-customer{
      width: 80px;
    }
    .hard-width-des-provinder{
      width: 53px;
    }
    .hard-width-1-des-provinder{
      width: 117px;
    }
    .hard-width-2-des-provinder{
      width: 179px;
    }
    .hard-width-3-des-provinder{
      width: 114px;
    }
    .hard-width-4-des-provinder{
      width: 114px;
    }
    .hard-width-5-des-provinder{
      width: 174px;
    }
    .hard-width-6-des-provinder{
      width: 126px;
    }
    .hard-width-7-des-provinder{
      width: 105px;
    }
    .hard-width-8-des-provinder{
      width: 97px;
    }
    .hard-width-9-des-provinder{
      width: 93px;
    }
    .hard-width-10-des-provinder{
      width: 165px;
    }
    .hard-width-11-des-provinder{
      width: 71px;
    }
    .hard-width-des-delivery{
      width: 55px;
    }
    .hard-width-1-des-delivery{
      width: 117px;
    }
    .hard-width-2-des-delivery{
      width: 178px;
    }
    .hard-width-3-des-delivery{
      width: 113px;
    }
    .hard-width-4-des-delivery{
      width: 113px;
    }
    .hard-width-6-des-delivery{
      width: 173px;

    }
    .hard-width-7-des-delivery{
      width: 125px;

    }
    .hard-width-8-des-delivery{
      width: 105px;
    }
    .hard-width-9-des-delivery{
      width: 422px;
    }

    .hard-width-1{
      width: 98px;
    }
    .hard-width-2{
      width: 180px;
    }
    .hard-width-2-1{
      width: 140px;
    }
    .hard-width-3{
      width: 95px;
    }
    .hard-width-4{
      width: 100px;
    }
    .hard-width-5{
      width: 158px;
    }
    .hard-width-6{
      width: 98px;
    }
    .hard-width-6-1{
      width: 180px;
    }
    .hard-width-7{
      width: 90px;
    }
    .hard-width-8{
      width: 90px;
    }
    .hard-width-9{
      width: 90px;
    }
    .hard-width-10{
      width: 159px;
    }
    .hard-width-10-1{
      width: 85px;
    }
    .hard-width-11{
      width: 70px;
    }
    .hard-width-11-1{
      width: 85px;
    }

    // css th customer
    .cus-hard-width-1{
      width: 180px !important;
    }
    .cus-hard-width-2{
      width: 200px !important;
    }
    .cus-hard-width-3{
      width: 160px !important;
    }
    .cus-hard-width-4{
      width: 140px !important;
    }
    .cus-hard-width-5{
      width: 170px !important;
    }
    .cus-hard-width-6{
      width: 170px !important;
    }
    .cus-hard-width-7{
      width: 140px !important;
    }
    .cus-hard-width-8{
      width: 140px !important;
    }

    // css th sale
    .sale-hard-width-1{
      width: 120px !important;
    }
    .sale-hard-width-2{
      width: 180px !important;
    }
    .sale-hard-width-3{
      width: 160px !important;
    }
    .sale-hard-width-4{
      width: 140px !important;
    }
    .sale-hard-width-5{
      width: 80px !important;
    }

    // css th delivery
    .deli-hard-width-1{
      width: 180px !important;
    }
    .deli-hard-width-2{
      width: 250px !important;
    }
    .deli-hard-width-3{
      width: 250px !important;
    }
    .deli-hard-width-4{
      width: 250px !important;
    }
    .deli-hard-width-5{
      width: 80px !important;
    }
  }

 .btn-outline-mpro-primary-detail {
    position: relative;
    width: 60px;
    min-height: 30px;
    background: #4C6E90;
    box-shadow: inset 0px -2px 3px 3px #4a5b6e;
    border-radius: 8px;
  }

  .btn-outline-mpro-primary-detail::before {
    content: "";
    display: block;
    position: absolute;
    width: 45px;
    height: 3px;
    top: 0.1rem;

    background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
    filter: blur(3px);
    border-radius: 8px;
  }
  .btn-outline-mpro-primary-detail::after {
    content: "";
    display: block;
    position: absolute;
    width: 45px;
    height: 3px;
    top: 0.1rem;

    background: linear-gradient(180deg, #C7E1F8 -183.33%, rgba(182, 220, 255, 0.3) 266.67%);
    filter: blur(3px);
    border-radius: 8px;
  }

  .icon-hover {
    font-size: 0.9rem;
    vertical-align: middle;
    color: white !important;
    background-color: #4C6E90;

    &:hover {
      color: black !important;
      text-decoration: none;
      background: white;

      i {
        color: black;
      }
    }
  }


  .btn-main-action {
    height: 80px;
  }

  .btn-action {
    min-width: 15% !important;
  }

  .content {
    .background-tab-active {
      background-color: $background-color-green !important;
      color: white !important;
    }

    .background-tab-inactive {
      background-color: white;
      color: black;
      cursor: pointer;

      &:hover {
        background: $background-color-green-light;
        font-weight: bold;
      }
    }
  }

  .highlight-row-detail {
    background: #b2d7ea !important;
  }

  .breadcrumb-item a {
    color: $background-color-green;
  }
}


// table scroll
.table-scroll {
  /*width:100%; */
  empty-cells: show;

  /* Decoration */
  border-spacing: 0;
}

.table-scroll thead {
  background-color: #f1f1f1;
  position: relative;
  width: 100%;
  clear: both;
  z-index: 9;
}

.table-scroll tbody {
  /* Position */
  position: relative;
  width: 100%;
  overflow-y: auto;
  /* Decoration */
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  clear: both;
  display: contents;
}

.table-scroll tr {
  width: 100%;
  background: #f2f7fc;
}

.table-scroll td, .table-scroll th {
  padding: 0.7rem;
}

/* Other options */

.body-half-screen {
  max-height: calc(100vh - 295px);
}

.small-col {
  flex-basis: 10%;
}

// custom height top
.custom-height-top {
  height: calc(100vh - 50px);
}

//
.custom-header-body-content {
  height: calc(100% - 50px);
  position: relative;
  overflow: auto;
  margin-bottom: 2rem;
}

//disable
.disabledItem {
  pointer-events: none;
  opacity: 0.6;
}

//
.border-header {
  border-bottom: 1px solid $background-color-green;
  background: $green-background;
}


.wrapper {
  padding-left: $sidebar-width;

  .sidebar-wrapper {
    z-index: 1000;
    position: absolute;
    left: 0;
    width: 227px;
    height: calc(100% - 50px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    background: #000;
    background: $primary;
    @extend .scrollbar;
    box-shadow: inset -1.25px 0px 5px 3px #6589ad;
  }

  .page-content-wrapper {
    position: relative;
    padding: 15px;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 50px);

    .body-content {
      padding-bottom: 25px;
    }
  }

  &.toggled {
    padding-left: $mini-sidebar-width;

    #sidebar-wrapper {
      width: $mini-sidebar-width;

      .sidebar-nav {
        width: $mini-sidebar-width;

        li a span {
          display: none;
        }
      }
    }
  }
}

// btn action
.act-vertical-buttons {
  padding: 0.75rem 1.5rem;
  margin: -1.5rem -1.5rem 0 -1.5rem;
  min-height: 4rem;
  text-align: center;
  vertical-align: middle;
  .btn {
    margin-right: 0.75rem;
    display: inline-block;
    text-align: center;
    box-shadow: 1px 1px 2px rgb(85 97 135 / 10%);
    padding: 0.5625rem;

    i {
      font-size: $main-font-size * 1.75;
      line-height: 1.2;
      display: block;
    }

    label {
      margin-bottom: 0;
      font-size: $main-font-size * 1.25;
      line-height: 1.2;
      width: $main-font-size * 5;
    }
  }

  .btn-action {
    padding-left: 10px;
    padding-top: 29px
  }
}

@import '~@fortawesome/fontawesome-free/scss/brands';
th {
  .th-sortable {
    cursor: pointer;
    white-space: nowrap;

    .th-name {
      //width: calc(100% - 10px);
      white-space: pre-wrap;;
      overflow: hidden;
      font-weight: normal !important;
      font-size: 15px;
    }

    .th-icon {
      width: 10px;
      padding-left: 5px;

      i.fa:after {
        content: fa-content($fa-var-sort);
        margin-right: .3rem;
        font-size: 22px;
      }
    }
  }
}

.asc i.fa:after {
  content: fa-content($fa-var-sort-up) !important;
  font-size: 22px;
}

.desc i.fa:after {
  content: fa-content($fa-var-sort-down) !important;
  font-size: 22px;
}

.item-required {
  color: red;
}

.btn-export-detail {
  border: solid 1px #385723;
  background-color: #f5f5f5;
  //color: #cdcdcd;
  float: right;
  &:hover {
    border: solid 1px #385723;
    background: #4C6E90;
    color: white;
  }
}

.icon-export-hover {
  font-size: 0.9rem;
  vertical-align: middle;
  color: black !important;
  background-color: white !important;

  &:hover {
    color: white !important;
    text-decoration: none;
    background: #4C6E90 !important;

    i {
      color: black;
    }
  }
  &:focus{
    color: white !important;
    text-decoration: none;
    background: #4C6E90 !important;

    i {
      color: black;
    }
  }
}

  table th {
    vertical-align: middle !important;
  }

  table tbody td span {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .middle-content {
    vertical-align: middle !important;
  }

  .dialog-content {
    white-space: pre-wrap;
  }

  .modal-body {
    max-height: 60vh;
    overflow: auto;
    white-space: pre-wrap !important;
  }

  input:disabled {
    pointer-events: none !important;
  }


@page {
  margin: auto;
  size: auto !important;
}

.btn-header-left {
  width: 110px;
  height: 55px;
}
.btn-header-right {
  width: 110px;
  height: 55px;
  float: right;
}
.btn-radius{
  border-radius: 12px;
}

.form-check-input:checked {
  background-color: #5273ff;
  border-color: #0a5394;
}

.form-check-input {
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
}

.text-underlined{
  text-decoration-line: underline;
  text-underline-position: under;
}
.text-letter{
  letter-spacing: 5px;
}

.B3{
  ngb-datepicker {
    min-width: 10.4rem;
    .ngb-dp-header ngb-datepicker-navigation{

      .ngb-dp-arrow {
        display: flex;
        flex: 1 1 auto;
        height: 2rem;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        width: 0.9rem;

        .ngb-dp-arrow-btn {
          margin: 0;

          .ngb-dp-navigation-chevron {
            margin-left: 0rem;
            margin-right: 0;
            width: 0.7em;
            height: .7em;
          }
        }
      }
      ngb-datepicker-navigation-select>.custom-select {
        width: 66px !important;
        flex: 1 1 auto;
        font-size: 0.875rem;
        height: 1.5rem;
        padding: 0 0rem;
      }
    }
    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday{
      width: 1.39rem;
    }
    .btn-light:hover {
      color: #f50606;
    }
    [ngbDatepickerDayView] {
      width: 1rem;
    }
  }

  .input-group .btn {
    z-index: 0;
  }
}
.form-check-input{
  border: 1px solid rgb(37 28 28 / 81%);
  &:checked {
    border: none;
    background-color: #5273ff;
    border-color: #0a5394;

    &[type="radio"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }
  }
}

.txt-red{
  color: red;
}

.btn-cursor{
  cursor: pointer;
}

@media print {
  .custom-header-body-content{
    height: unset;
  }
}

.fix-width {
  max-width: 120px;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
  .box-right-title {
    width: 45% !important;
  }
  .box .box-right .box-right-provider input {
    width: 60% !important;
    height: 4rem !important;
  }
}


@page
    {
        size: auto;   /* auto is the initial value */
        margin: 5mm 10mm 10mm 10mm;  /* this affects the margin in the printer settings */
    }
//@media screen and (min-width: 375px) and (max-width: 7680px) {
//  .master-css .hard-width-1 {
//    width: 140px;
//  }
//}

.disableDiv{
  pointer-events:none;
}
.breadcrumb_total_row{
  padding-right: 500px;
  @media screen and (max-width: 1280px) {
    padding-right: 230px;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 245px;
  }
}
.breadcrumb_total{
  padding-right: 300px;
  @media screen and (max-width: 1280px) {
    padding-right: 105px;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 130px;
  }
}
