// new variables
$primary-light: #3d85c6;
$lighter: #EDEDED;
$blue: #1a76d2;

// Overwrite bootstrap
$primary: #0a5394;
$secondary: #DDD;
$success: #26B99A;
$info: #1a76d2;
$light: #F9F9F9;
$dark: #2A3F54;
$dark-less: #73879C;

$font-family-sans-serif: Meiryo, "Noto Sans JP", "M PLUS 1p", sans-serif, Lato, "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$body-color: #000000;
$font-size-base: 0.875rem; // 14px
$font-size-sm: 0.75rem; // 12px (smaller size)
$font-size-lg: 1.125rem; // 18px (larger size)

// table
$table-border-color: lighten($dark-less, 15);
$table-striped-bg: $lighter;
$table-cell-padding-x: .25rem;
$table-cell-padding-y: .25rem;

// Button, Input
$input-btn-padding-x: .75rem;
$input-btn-padding-y: .25rem;
$input-btn-padding-x-sm: 0.25rem;
$input-btn-padding-y-sm: 0.1rem;
$input-placeholder-color: $secondary;
$input-disabled-bg: $lighter;
$input-border-color: $dark-less;
$link-color: $blue;

// others
$cursors: auto, default, none, context-menu, help, pointer, progress, wait, cell, crosshair, text, vertical-text, alias, copy, move, no-drop, not-allowed, all-scroll, col-resize, row-resize, n-resize, e-resize, s-resize, w-resize, ns-resize, ew-resize, ne-resize, nw-resize, se-resize, sw-resize, nesw-resize, nwse-resize;
$border-radius: .25rem;
$tooltip-max-width: 30rem;
$tooltip-bg: $primary;
$dropdown-link-hover-bg: $lighter;

$custom-colors: (
  "blue": $blue,
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$theme-colors: map-merge($theme-colors, $custom-colors);

$green-dark: #1F4E79;
$green-light: #8696ad;
$background-color-green-light: $green-light;
$background-color-green: $green-dark;
$green-background: #F5F5F5;


$card-border-color:  none;
$sidebar-width: 204px;
$mini-sidebar-width: 50px;
$main-color: lighten(#242939, 25%);
$main-font-size: .75rem;
$base-font-size: 16px;

//btn sort
$fa-var-sort: \f0dc;
$fa-var-sort-up: \f0de;
$fa-var-sort-down: \f0dd;
$card-border-color:  none;
// custom  css for each user: intermediary-company
$purple-dark: #a681b7;
$purple-light: #D5F4F0;
$background-color-purple: $purple-dark;
$background-color-purple-light: $purple-light;
$purple-background:  #a681b711;
// custom  css for each user: driver
$blue-light: #68aee0;
$blue-light-plus: #D5F4F0;
$background-color-blue: $blue-light;
$background-color-blue-plus: $blue-light-plus;
$blue-background: #68aee011;
// custom  css for each user: customer
$orange-dark: #dea366;
$orange-light: #D5F4F0;
$background-color-orange: $orange-dark;
$background-color-orange-light: $orange-light;
$orange-background: #dea36611;

