// to zero important
$target: ("h":height, "w":width);
@each $name, $option in $target {
  .#{$name}-0 {
    #{$option}: 0 !important;
  }
}

// width-height class
@for $i from 1 through 100 {
  .w-#{$i} {
    width: $i * 1%;
  }
  .h-#{$i} {
    height: $i * 1%;
  }
  .vw-#{$i} {
    width: $i * 1vw;
  }
  .vh-#{$i} {
    height: $i * 1vh;
  }
  .min-vw-#{$i} {
    min-width: $i * 1vw;
  }
  .min-vh-#{$i} {
    min-height: $i * 1vh;
  }
  .ellipsis-#{$i}-line {
    display: -webkit-box !important;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
}

// font-size class
@for $i from 10 through 40 {
  .fs#{$i} {
    font-size: _rem($i * 1px);
  }
  .radius-#{$i} {
    border-radius: _rem($i * 1px);
  }
}

// cursor class
@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: $cursor !important;
  }
}
